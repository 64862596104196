<template>
    <div class="header date">
        <div v-if="!disableArrows && CheckListenerExists('PrevDate')" class="fas fa-angle-left" v-on:click="PrevDate()"></div>
        <div class="label bold">{{label.toUpperCase()}}</div>
        <div v-if="!disableArrows && CheckListenerExists('NextDate')"  class="fas fa-angle-right" v-on:click="NextDate()"></div>
        <div v-if="!disableCalendar && CheckListenerExists('CalendarOpen')"  class="fas fa-calendar" v-on:click="CalendarOpen($event)"></div>
        <div v-if="!disableToday && CheckListenerExists('SetToday')" class="label today" v-on:click="SetToday($event)">Heute</div>
    </div>
</template>

<script>
    export default {
        name: "dateselectionheader",
        props:{
            label: String,
            disableArrows: Boolean,
            disableCalendar: Boolean,
            disableToday: Boolean,
        },
        methods:{
            CheckListenerExists(eventName){
                return this.$listeners[eventName];
            },
            PrevDate(){
                this.$emit('PrevDate');
            },
            NextDate(){
                this.$emit('NextDate');
            },
            SetToday($event){
                this.$emit('SetToday', $event);
            },
            CalendarOpen($event){
                this.$emit('CalendarOpen', $event);
            },
        },
    }
</script>

<style scoped>


    .header.date{
        display: inline-flex;
        align-items: center;
        position: absolute;
        z-index: 10;
        background: none;
        left: 0;
        right: initial;
        width: initial;
        padding: 0 20px;
    }

    .header.date .label{
        color: var(--contrast-4);
        font-family: DistrictProLight;
        font-weight: 200;
    }

    .header.date .label.today{
        font-size: 15px;
        cursor:pointer;
    }

    .header.date .fa-angle-right,.header.date .fa-angle-left{
        color: var(--contrast-4);
        font-size: 20px;
        padding: 5px;
        cursor:pointer;
    }

    .header.date .fa-calendar{
        color: var(--contrast-4);
    }

    .header.date .fa-angle-right:hover,.header.date .fa-angle-left:hover, .header.date .fa-calendar:hover, .header.date .today:hover{
        color: var(--bg) !important;
    }

    .header.date .label.bold{
        font-family: DistrictProBold;
        font-weight: 800;
        margin: -3px 5px 0;
    }

    .header.date .fa-calendar{
        margin: 0 10px;
        font-size: 20px;
    }
</style>